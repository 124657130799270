<template>
  <div>
    <div>
      <el-button
        class="select-product-btn"
        plain
        size="mini"
        @click="dialogVisible = true"
        >选择课程
      </el-button>
    </div>

    <el-dialog
      :show-close="false"
      :visible.sync="dialogVisible"
      append-to-body
      title="选择课程"
      width="40%"
    >
      <div class="search-data">
        <el-input
          v-model="name"
          placeholder="名称"
          size="mini"
          style="width: 200px"
        />
        <el-button class="margin-l-sm" size="mini" @click="search"
          >搜索
        </el-button>
      </div>
      <el-tabs v-model="active" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="课程" name="1">
          <div v-if="active === '1'" class="table">
            <el-table
              ref="multipleTable"
              v-loading="loading"
              :data="list"
              row-key="id"
              style="width: 100%"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                :reserve-selection="true"
                type="selection"
                width="55"
              ></el-table-column>
              <el-table-column label="ID" prop="id" />
              <el-table-column label="名称" prop="name" />
              <el-table-column label="封面">
                <template slot-scope="scope">
                  <el-image
                    :src="imageUrl + scope.row.pic"
                    style="width: 120px"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="打卡课程" name="2">
          <div v-if="active === '2'" class="table">
            <el-table
              ref="multipleTable"
              v-loading="loading"
              :data="list"
              row-key="id"
              style="width: 100%"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                :reserve-selection="true"
                type="selection"
                width="55"
              ></el-table-column>
              <el-table-column label="ID" prop="id" />
              <el-table-column label="名称" prop="name" />
              <el-table-column label="封面">
                <template slot-scope="scope">
                  <el-image
                    :src="imageUrl + scope.row.pic"
                    style="width: 120px"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="table-batch margin-l-sm">
        <el-pagination
          :current-page="page"
          :page-size="pageSize"
          :page-sizes="[10, 20]"
          :total="total"
          background
          class="fl-r"
          layout="total, sizes, prev, pager, next, jumper"
          small
          @size-change="setPageSize"
          @current-change="setPage"
        >
        </el-pagination>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="select">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "selectProduct",
  data() {
    return {
      imageUrl: config.imageUrl,
      dialogVisible: false,
      loading: true,
      list: [],
      total: 0,
      multipleSelection: [],
      name: "",
      page: 1,
      pageSize: 10,
      active: "1",
    };
  },
  methods: {
    ...mapActions("course", ["courseList"]),
    ...mapActions("punch", ["getPunchList"]),
    deleteUserCourse(item) {},
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.init();
    },
    setPage(page) {
      this.page = page;
      this.init();
    },
    handleClick() {
      this.page = 1;
      this.init();
    },
    search() {
      this.page = 1;
      this.init();
    },
    select() {
      let course_list = [],
        check_course_list = [];
      if (this.active === "1") {
        course_list = this.multipleSelection;
      } else {
        check_course_list = this.multipleSelection.map((value) => {
          return {
            ...value,
            is_all: 0,
          };
        });
      }
      this.$emit("success", { course_list, check_course_list });
      this.dialogVisible = false;
    },
    async init() {
      try {
        this.loading = true;
        let form = {
          name: this.name,
          page: this.page,
          pageSize: this.pageSize,
        };
        let res;
        if (this.active === "1") {
          res = await this.courseList(form);
        } else {
          res = await this.getPunchList(form);
        }
        this.list = res.data.list;
        this.total = res.data.total;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.table {
  height: 50vh;
  overflow: auto;
}

.table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

/* 滚动条滑块 */
.table::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}

.search-data {
  margin-left: 97px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
</style>